import axiosInstances from '@/libs/axios-instances'
import { computed, ref } from '@vue/composition-api'
import handleFormData from '../common/handleFormData'

export default function azkarCategory() {
  const { formData, setFormData } = handleFormData()
  const azkarCategoryItem = ref({
    status: true,
  })

  const formattedazkarCategory = computed(() => ({
    ...azkarCategoryItem.value,
    parent_id: azkarCategoryItem.value.parent?.id,
    type: azkarCategoryItem.value.type?.value,
    image: azkarCategoryItem.value.image ? azkarCategoryItem.value.image[0] : null,
  }))

  const listazkarCategoryRequest = params => axiosInstances.users.get('/internalops/azkar-categories', { params }).then(res => res)
  const listAllazkarCategoryRequest = params => axiosInstances.users.get('/internalops/azkar-categories-list', { params }).then(res => res)

  const getazkarCategoryRequest = id => axiosInstances.users.get(`/internalops/azkar-categories/${id}`).then(res => res)
  const prepareFormData = () => {
    setFormData(formattedazkarCategory.value)
  }

  const addazkarCategoryRequest = () => axiosInstances.users.post('/internalops/azkar-categories', formData.value).then(res => res)

  const editazkarCategoryRequest = id => axiosInstances.users.post(`/internalops/azkar-categories/${id}?_method=PUT`, formData.value).then(res => res)

  const deleteazkarCategoryRequest = id => axiosInstances.users.delete(`/internalops/azkar-categories/${id}`).then(res => res)

  return {
    azkarCategory: azkarCategoryItem,
    prepareFormData,
    listazkarCategoryRequest,
    getazkarCategoryRequest,
    addazkarCategoryRequest,
    editazkarCategoryRequest,
    deleteazkarCategoryRequest,
    listAllazkarCategoryRequest,
  }
}
