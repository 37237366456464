<template>
  <fieldset :disabled="$attrs.disabled">
    <div class="p-1 border mb-2">
      <h4 class="my-1">
        Zikr Details
      </h4>

      <b-form-row>
        <!-- Content en -->
        <b-col md="12">
          <text-area-input
            id="zikr-content_en"
            rules="required"
            name="Content En"
            :text.sync="item.content_en"
          />
        </b-col>
        <!-- Content ar -->
        <b-col md="12">
          <text-area-input
            id="zikr-content_ar"
            rules="required"
            input-class="direction-rtl"
            name="Content Ar"
            :text.sync="item.content_ar"
          />
        </b-col>
        <b-col md="12">
          <!-- letters_en -->
          <text-area-input
            id="zikr-letters_en"
            name="Transliteration"
            rules=""
            :text.sync="item.letters_en"
            placeholder="Ex : سبحان الله و بحمده  Subḥānallāhi wa biḥamdih."
          />
        </b-col>
        <b-col md="6">
          <!-- narrator en -->
          <text-input
            id="zikr-narrator_en"
            name="Narrator En"
            rules=""
            :text.sync="item.narrator_en"
          />
        </b-col>
        <b-col md="6">
          <!-- narrator ar -->
          <text-input
            id="zikr-narrator_ar"
            name="Narrator Ar"
            input-class="direction-rtl"
            rules=""
            :text.sync="item.narrator_ar"
          />
        </b-col>
        <!-- about en -->
        <b-col md="12">
          <text-area-input
            id="zikr-about_en"
            rules=""
            name="About En"
            :text.sync="item.about_en"
          />
        </b-col>
        <!-- about ar -->
        <b-col md="12">
          <text-area-input
            id="zikr-about_ar"
            rules=""
            input-class="direction-rtl"
            name="About Ar"
            :text.sync="item.about_ar"
          />
        </b-col>
        <b-col md="6">
          <!-- count -->
          <text-input
            id="zikr-count"
            name="Count"
            min="1"
            rules="min_value:1"
            :text.sync="item.count"
            type="number"
          />
        </b-col>
        <!-- zikr Category -->
        <b-col
          md="6"
          class="mb-1"
        >
          <azkar-category-select
            rules="required"
            :disabled="$attrs.disabled"
            :category.sync="item.category"
          />
        </b-col>
      </b-form-row>
    </div>
  </fieldset>
</template>
<script>
import Helpers from '@/common/compositions/common/Helpers'
import AzkarCategorySelect from '@/common/components/azkar/AzkarCategorySelect.vue'
import TextAreaInput from '../common/FormInputs/TextAreaInput.vue'
import TextInput from '../common/FormInputs/TextInput.vue'

export default {
  name: 'AzkarInputs',
  components: {
    TextInput,
    TextAreaInput,
    AzkarCategorySelect,
  },
  props: {
    item: { type: Object, default: () => {} },
  },
  computed: {
    isAddForm() {
      return !this.$route.params.id
    },
  },
  setup() {
    const { isImage } = Helpers()
    return { isImage }
  },
}
</script>
<style lang="scss">

</style>
