import axiosInstances from '@/libs/axios-instances'
import { computed, ref } from '@vue/composition-api'
import handleFormData from '../common/handleFormData'

export default function azkar() {
  const { formData, setFormData } = handleFormData()
  const azkarItem = ref({
    status: true,
    count: 1,
  })

  const formattedazkar = computed(() => ({
    ...azkarItem.value,
    azkar_category_id: azkarItem.value.category?.id,
  }))

  const listAzkarRequest = params => axiosInstances.users.get('/internalops/azkar', { params }).then(res => res)

  const getAzkarRequest = id => axiosInstances.users.get(`/internalops/azkar/${id}`).then(res => res)
  const prepareFormData = () => {
    setFormData(formattedazkar.value)
  }

  const addAzkarRequest = () => axiosInstances.users.post('/internalops/azkar', formData.value).then(res => res)

  const editAzkarRequest = id => axiosInstances.users.post(`/internalops/azkar/${id}?_method=PUT`, formData.value).then(res => res)

  const deleteAzkarRequest = id => axiosInstances.users.delete(`/internalops/azkar/${id}`).then(res => res)

  return {
    azkar: azkarItem,
    prepareFormData,
    listAzkarRequest,
    getAzkarRequest,
    addAzkarRequest,
    editAzkarRequest,
    deleteAzkarRequest,
  }
}
